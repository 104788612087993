import React from 'react'
import { Flipper, Flipped } from "react-flip-toolkit"
import oko from '../../images/ikony/oko.svg'
import TransitionLink from 'gatsby-plugin-transition-link'
import ButtonPaluch from '../buttons/button_goto.js'
import dummy from '../../images/projekt_dummy.png'
import back from '../../images/ikony/goback.svg'
import paluch from '../../images/ikony/paluch.svg'
// import imger from {this.props.img}
import Img from "gatsby-image"
class ProjektHeader extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      projektFlip: false,
      chowaj: false,
      left: 0,
      top: 0,
      clicked: false,
      wybrany: false,
      mobile: false,
    }
    this.check = React.createRef();
    this.obraz = React.createRef();

  }

  componentDidMount() {
    this.domRect = this.check.current.getBoundingClientRect()
    if(this.domRect.width < 1025) {
      this.setState({
          mobile: true,
      })
      console.log("TO JEST MOBILE");
    }
  }

  ogarnijKursor = (e) => {
    if(this.state.mobile){

    }
    else {
      if(this.state.clicked === true) {
        this.obraz.current.setAttribute('style', 'left:' + 80 + 'px; opacity: 1;');
      }
      else {
        var yyy = e.pageY + 5
        var xxx = e.pageX + 5
        this.obraz.current.setAttribute('style', 'top:' + yyy + 'px; left:' + xxx + 'px; opacity: 1;');
      }
    }
    
 
  }
  wylaczObraz = (e) => {
    if (this.state.projektFlip == true) {

    }
    else {
      this.obraz.current.setAttribute('style', 'display: none');
    }

  }



  okladkaAktywna = () => {
    this.setState({
      wybrany: !this.state.wybrany,
    })
  }


  wylaczFollow = () => {

    this.setState({
      clicked: !this.state.clicked,
    })

  }



  flipProjekt = () => {

    this.setState({
      projektFlip: !this.state.projektFlip,
      chowaj: !this.state.chowaj
    })

    this.props.handleChildClick()

  }

  render() {
    const flipped = this.state.projektFlip ? 'projekt-aktywny' : ''
    const azakres = Object.values(this.props.zakres)

    if(this.props.slug === "/projekty/") {
      return  <div className={`${flipped}`}>
           <div ref={this.check} style={{position: "fixed", top: 0, width: '100vw'}}></div>
         
        <div style={{position: 'relative'}} >
    
          

           
            
              <div
                style={{cursor: 'not-allowed'}}
                className="lista-item">
                 
              
               
                  <h3 className="projekt-tagi">{this.props.klient}
                  
                <div>
                {this.props.filtracja.map((f, i) => (
                  <span className="przycisk" key={i} data-filtry={f}><img src={paluch} />{f}</span>
                ))}
                </div>
                </h3>
            
               
                <span className="przycisk wkrotce" >CASE STUDY WKRÓTCE</span>
                
                <div className={(this.state.wybrany ? "okladka okladka-aktywna" : "okladka")} ref={this.obraz}>
                <Img fluid={this.props.img} />
              
                </div>
               
               

              </div>
            
          
   
          
        

        </div>
       
      </div>
     
    }
    else {
      return <Flipper flipKey={this.state}>
        <div ref={this.check} style={{position: "fixed", top: 0, width: '100vw'}}></div>
      <div onClick={this.wylaczFollow} className={`${flipped}`}>
          
        <div style={{position: 'relative'}} onClick={this.flipProjekt}>
    
          

              <Flipped flipId="projekt-flipper">
            
              <div
                onMouseLeave={this.wylaczObraz}
                onMouseMove={this.ogarnijKursor}
                onClick={this.okladkaAktywna}
                className="lista-item">
                  <TransitionLink
            to={this.props.slug}
            exit={{ length: 0, delay: 1 }}
            entry={{ appearAfter: 0 }}
            style={{
              position: 'absolute',
              top: '0',
              zIndex: '10',
              left: '0',
              width: '100%',
              height: '100%',
            }}
          >
                   </TransitionLink>
                <TransitionLink to="/projekty">
                  <img className="projekt-header-cofnij" src={back} />
                </TransitionLink>
               
                  <h3 className="projekt-tagi">{this.props.klient}
                  
                <div>
                {this.props.filtracja.map((f, i) => (
                  <span className="przycisk" key={i} data-filtry={f}><img src={paluch} />{f}</span>
                ))}
                </div>
                </h3>
            
               
                <div className='projekt-zajawka'>
                  <img src={oko} />
                  <span className="przycisk">{this.props.data}</span>
                  <div className="projekt-zajawka-kategorie">
                  {azakres.map(z =>  <span className="przycisk">{z}</span>)}
                           
                         
                        </div>
           
                  <p>{this.props.zajawka}</p>





                </div>
                
                <div className={(this.state.wybrany ? "okladka okladka-aktywna" : "okladka")} ref={this.obraz}>
                <Img fluid={this.props.img} />
                </div>
               
               

              </div>
            
              </Flipped>
   
          
        

        </div>
       
      </div>
      </Flipper>
    }
   
    
  }
}

export default ProjektHeader



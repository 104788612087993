import React, { useState } from 'react'
import { Flipper, Flipped } from "react-flip-toolkit";
import { Link } from 'gatsby'
import Layout from '../../components/layout'
import { motion } from "framer-motion";
import TransitionLink from 'gatsby-plugin-transition-link'
import Bread from '../../components/bread'
import Seo from '../../components/seo.js'
import { Parallax } from 'react-scroll-parallax'



import Formularz from '../../components/formularz/formularz'
import ProjektHeader from '../../components/projekty/projekt_header'
import Slider from '../../components/slider/slider'
import KompLax from '../../components/projekty/comp/kompozycja_lax'


import dummy from '../../images/projekt_dummy.png'
import usmiech from '../../images/ikony/usmiech.svg'
import okladka from '../../images/projekt_dummy.png'
import strzalka from '../../images/ikony/strzalka.svg'
import oko from '../../images/ikony/oko.svg'
import style from './projekt.module.css'
import back from '../../images/ikony/goback.svg'


const okladka_str = okladka.toString()
let count = 0;

const intro_dodatkowe = [
  {id: 1, img: "", info: "Pojęcie archetypu zostało spopularyzowane w pierwszej połowie XX w. przez Carla Gustava Junga, dla którego Inspirację stanowiły pisma starożytnych m.in. św. "},
  {id: 2, img: "", info: "Pojęcie archetypu zostało spopularyzowane w pierwszej połowie XX w. przez Carla Gustava Junga, dla którego Inspirację stanowiły pisma starożytnych m.in. św. "}
]

const architektura = [
  {numeracja: "01",
  usluga: "RESEARCH I KONCEPCJA",
  dzialania: "Księga znaku, System identyfikacji",
  opis: "Pojęcie archetypu zostało spopularyzowane w pierwszej połowie XX w. przez Carla Gustava Junga, dla którego Inspirację stanowiły pisma starożytnych, m.in. św. Augustyna i Dionizego Pseudo-Areopagity. Szwajcarski psychiatra zdefiniował archetypy jako wysoko rozwinięte elementy nieświadomości zbiorowej, pewne ",
  okladka: okladka_str},
  {numeracja: "02",
  usluga: "STRATEGIA MARKI",
  dzialania: "Księga znaku, System identyfikacji",
  opis: "Pojęcie archetypu zostało spopularyzowane w pierwszej połowie XX w. przez Carla Gustava Junga, dla którego Inspirację stanowiły pisma starożytnych, m.in. św. Augustyna i Dionizego Pseudo-Areopagity. Szwajcarski psychiatra zdefiniował archetypy jako wysoko rozwinięte elementy nieświadomości zbiorowej, pewne ",
  okladka: okladka_str},
  {numeracja: "03",
  usluga: "IDENTYFIKACJA MARKI",
  dzialania: "Księga znaku, System identyfikacji",
  opis: "Pojęcie archetypu zostało spopularyzowane w pierwszej połowie XX w. przez Carla Gustava Junga, dla którego Inspirację stanowiły pisma starożytnych, m.in. św. Augustyna i Dionizego Pseudo-Areopagity. Szwajcarski psychiatra zdefiniował archetypy jako wysoko rozwinięte elementy nieświadomości zbiorowej, pewne ",
  okladka: okladka_str},
  {numeracja: "04",
  usluga: "CORPORATE DESIGN",
  dzialania: "Księga znaku, System identyfikacji",
  opis: "Pojęcie archetypu zostało spopularyzowane w pierwszej połowie XX w. przez Carla Gustava Junga, dla którego Inspirację stanowiły pisma starożytnych, m.in. św. Augustyna i Dionizego Pseudo-Areopagity. Szwajcarski psychiatra zdefiniował archetypy jako wysoko rozwinięte elementy nieświadomości zbiorowej, pewne ",
  okladka: okladka_str},
  {numeracja: "05",
  usluga: "EDITORIAL DESIGN",
  dzialania: "Księga znaku, System identyfikacji",
  opis: "Pojęcie archetypu zostało spopularyzowane w pierwszej połowie XX w. przez Carla Gustava Junga, dla którego Inspirację stanowiły pisma starożytnych, m.in. św. Augustyna i Dionizego Pseudo-Areopagity. Szwajcarski psychiatra zdefiniował archetypy jako wysoko rozwinięte elementy nieświadomości zbiorowej, pewne ",
  okladka: okladka_str},
  {numeracja: "06",
  usluga: "PRINT DESIGN",
  dzialania: "Księga znaku, System identyfikacji",
  opis: "Pojęcie archetypu zostało spopularyzowane w pierwszej połowie XX w. przez Carla Gustava Junga, dla którego Inspirację stanowiły pisma starożytnych, m.in. św. Augustyna i Dionizego Pseudo-Areopagity. Szwajcarski psychiatra zdefiniował archetypy jako wysoko rozwinięte elementy nieświadomości zbiorowej, pewne ",
  okladka: okladka_str},
  {numeracja: "07",
  usluga: "WEBDESIGN",
  dzialania: "Księga znaku, System identyfikacji",
  opis: "Pojęcie archetypu zostało spopularyzowane w pierwszej połowie XX w. przez Carla Gustava Junga, dla którego Inspirację stanowiły pisma starożytnych, m.in. św. Augustyna i Dionizego Pseudo-Areopagity. Szwajcarski psychiatra zdefiniował archetypy jako wysoko rozwinięte elementy nieświadomości zbiorowej, pewne ",
  okladka: okladka_str},
  {numeracja: "08",
  usluga: "DIGITAL DESIGN",
  dzialania: "Księga znaku, System identyfikacji",
  opis: "Pojęcie archetypu zostało spopularyzowane w pierwszej połowie XX w. przez Carla Gustava Junga, dla którego Inspirację stanowiły pisma starożytnych, m.in. św. Augustyna i Dionizego Pseudo-Areopagity. Szwajcarski psychiatra zdefiniował archetypy jako wysoko rozwinięte elementy nieświadomości zbiorowej, pewne ",
  okladka: okladka_str},
  {numeracja: "09",
  usluga: "DIGITAL DESIGN",
  dzialania: "Księga znaku, System identyfikacji",
  opis: "Pojęcie archetypu zostało spopularyzowane w pierwszej połowie XX w. przez Carla Gustava Junga, dla którego Inspirację stanowiły pisma starożytnych, m.in. św. Augustyna i Dionizego Pseudo-Areopagity. Szwajcarski psychiatra zdefiniował archetypy jako wysoko rozwinięte elementy nieświadomości zbiorowej, pewne ",
  okladka: okladka_str}
]




const title = "PROJEKT"
const spring = {
  type: "spring",
  stiffness: 700,
  damping: 30
};



export default function Projekty() {

  const [isOn, setIsOn] = useState(false)

  const toggleSwitch = () => setIsOn(!isOn)


  return(
    <>
      <div id="projekt-bread" className="projekt-aktywny projekt-header">
 
        <div className="lista-item">
          <TransitionLink to="/projekty">
            <img className="projekt-header-cofnij" src={back} />
          </TransitionLink>
          <h3 >{title}</h3>
          <div className="projekt-zajawka">
            <img src={oko} />
            <span className="przycisk"></span>
            <h6></h6>
            <p></p>
          </div>
          
        </div>
        
      </div>
     

    

    
    <Seo title={title} />



      <div className="podstrona-wrapper">
      <img className="projekt-okladka" src={dummy} />
    <div className={["sekcja-padding", style.intro].join(' ')}>
      <div>
        <h6>Jak strategicznie myśleć?</h6>
        <h4>Our collective blurs the lines between art and business, employing best  sensual concepts to enhance experience of your brand. We devise strategies and implement them for physical and digital environments. We aim to bring people together. Osome. Our collective blurs the lines be-tween art and business, employing best</h4>
      </div>

      <aside>
      {intro_dodatkowe.map(i => (
        <div className="kolumna-info">
          <img src={usmiech} />
          <p>{i.info}</p>
        </div>
      ))}
      </aside>

    </div>





    <img className={["sekcja-padding", style.intro_okladka].join(' ')} src={okladka} />


    <div className={style.architektura}>
      <h3>ARCHITEKTURA PROJEKTU</h3>
      <div>

      {architektura.map(a => (
        <div data-rozdzial={a.numeracja}>
        <span className="przycisk">
        {[a.numeracja,". ", a.usluga]}
        </span>
        <img src={strzalka} />
          <div className={style.architektura_acco}>
            <img src={oko} />
            <h6>{a.dzialania}</h6>
          </div>
        </div>

      ))}
      </div>
    </div>
    </div>

    <KompLax 
        tytul1="ELO000000" 
        tekst1="Our collective blurs the lines between art and business, employing best  sensual concepts to enhance experience of your brand. We devise strategies and implement them for physical and digital environments."
        tytul2="ELOOOO"
        tekst2="Our collective blurs the lines between art and business, employing best  sensual concepts to enhance experience of your brand. We devise strategies and implement them for physical and digital environments."
      />
      {architektura.map(a => (
      <div data-rozdzial={a.numeracja} className={style.sekcja}>
          <div className={style.sekcja_intro}>
            <div>
              <span className="przycisk">{a.numeracja}.</span>
              <h3>{a.usluga}</h3>
              <p>{a.opis}</p>
            </div>
            <div>
              <div className={style.pigulka_button} onClick={toggleSwitch}>
                <span className="przycisk" style={{
                  textTransform: "capitalize"
                }}>Pigułka</span>
                  <div className={style.pigulka_wajcha} data-isOn={isOn}>
                    <motion.div className={style.pigulka_switch} layout transition={spring}  />
                  </div>
                <span className="przycisk" style={{
                  textTransform: "capitalize"
                }}>Rozszerzona</span>
              </div>
            </div>
        </div>

        <Parallax
            styleOuter={{
              overflow: 'hidden',
            }}
            y={[-10, 10]}>
          <img src={a.okladka.toString()} />
          </Parallax>
      </div>

    ))}




    <div className="sekcja-padding">
    <Slider />
    </div>
    <Formularz />




    </>
  )

}

import React from 'react'
import "slick-carousel/slick/slick.css"
import style from './kompozycja_lax.module.css'
import { Parallax } from 'react-scroll-parallax'
import dummy from '../../../images/projekt_dummy.png'

export default function KompLax(props) {
   
    return (
        <div className="sekcja-wrapper">
            <div className={style.komp_grid}>
                   
                    <div className={style.komp_gora}>
                    <Parallax y={[-5, 5]} className="lax-czas">
                        <div className={style.tekst}>
                            <h6>{props.tytul1}</h6>
                            <p>{props.tekst1}</p>
                        </div>
                    </Parallax>

                    <Parallax y={[5, -5]}>
                        <img className={style.obraz} src={dummy} />
                    </Parallax>
                </div>


                <div className={style.komp_dol}>
                    <Parallax y={[5, -5]} className="lax-czas">
                        <div className={style.tekst}>

                            <h6>{props.tytul2}</h6>

                            <p>{props.tekst2}</p>
                        </div>
                    </Parallax>

                    <Parallax y={[-5, 5]}>
                        <img className={style.obraz} src={dummy} />
                    </Parallax>
                </div>

            </div>
        </div>
    )
}